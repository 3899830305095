<template>
    <div class="">
        <!-- Content Header (Page header) -->
        <div class="">
           <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                            <div class="card ">
                                <div class="card-body table-responsive table-head">
                                    <div class="row bor-bot">
                                        <div class="col-md-8 pl-0">
                                           <h1 class="m-0 text-dark pt-2 pb-4 ">Member Score ({{ member.first_name }}  {{ member.last_name }}) </h1>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="text-right pb-3">
                                                <ol class="breadcrumb float-sm-right">
                                                    <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                                    <li class="breadcrumb-item"><a href="/admin/registrations">Individual Registrations List</a></li>
                                                    <li class="breadcrumb-item"><Breadcrumbs/></li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive mt-3 scoring-datatable">
                                        <div class="score-date-range-filter">
                                            <div class="datatable-start-date mr-2">
                                                    <div class="form-inline">
                                                        <label class="form-label mr-2">From Date</label>
                                                        <div class="form-group birthdate scoring-date">
                                                            <div class="birthdaypicker">
                                                                <date-pick
                                                                    v-model="filterStartDate"
                                                                    :format="'MM/DD/YYYY'"
                                                                    :displayFormat="'MM/DD/YYYY'"
                                                                    placeholder="start"
                                                                    v-mask="'##/##/####'"
                                                                    :parseDate="parseDatePick"
                                                                    @input="triggerFilter"
                                                                ></date-pick>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="datatable-end-date">
                                                    <div class="form-inline">
                                                        <label class="form-label mr-2">To Date</label>
                                                        <div class="form-group birthdate scoring-date">
                                                            <div class="birthdaypicker">
                                                                <date-pick
                                                                    v-model="filterEndDate"
                                                                    :format="'MM/DD/YYYY'"
                                                                    :displayFormat="'MM/DD/YYYY'"
                                                                    v-mask="'##/##/####'"
                                                                    :parseDate="parseDatePick"
                                                                    @input="triggerFilter"
                                                                ></date-pick>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th>Event Name</th>
                                                    <th>Event Date</th>
                                                    <th>Discipline</th>
                                                    <th>Shots Fired</th>
                                                    <th>Score</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- Data will be placed here -->
                                                <tr>
                                                    <td colspan="6">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

import $ from 'jquery';
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from 'moment';
import axios from 'axios';

export default {
    name: 'Scoring',
    components: { },
    data() {
        return{
            table:'',
            member: '',
            filterStartDate: '',
            filterEndDate: '',
            scoringApiUrl : '',
        }
    },
    methods: {
        formatDate(value) {
            var date=  moment(value, "YYYY-MM-DD hh:mm:ss");
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
        triggerFilter: function(event){
           var url = this.scoringApiUrl+"?start_date="+this.filterStartDate+"&end_date="+this.filterEndDate;
           this.table.ajax.url(url).load();
        }
    },
    mounted(){
        window.reg=this;
        // this.scoringApiUrl = process.env.VUE_APP_SHOOTING_EMS_URL + "api/events/get-member-score-info?member_id="+this.$route.params.member_id;
        this.scoringApiUrl = this.basePath + "api/member/"+this.$route.params.member_id+"/score";
        this.table = $("#list_table").DataTable({
            dom: 'lfrtip',
            "bFilter": true,
            processing: true,
            // serverSide: true,
            pageLength: 10,
            responsive: true,
            ajax: {
                url: this.scoringApiUrl,
                type: 'get',
                headers: this.adminHeaders,
                dateType: "json",
                contentType: "application/json; charset=utf-8",
            },
            columns: [
                { data: 'event_name', name: 'event_name', render: (data)=>{return data || "N/A";} },
                { data: "event_date", name: "event_date", render: (data)=>{return this.formatDate(data) || "N/A";} },
                { data: "discipline", name: "event.scoring", render: (data)=>{return data || "N/A";} },
                { data: "shots_fired", name: "score", render: (data)=>{return data || "N/A";} },
                { data: "score", name: "shots_fired", render: (data)=>{return data || "N/A";} },
            ],
            columnDefs: [
                {
                   "targets":1,
                   "type":"date"
                }
            ],
            order: [1, 'desc']
        });
       
       this.regId = this.$route.params.regId;
        axios.get(this.basePath + 'api/admin/get_member/' + this.regId, {headers: this.adminHeaders})
            .then((res) => {
              this.member = res.data.data;
            })
            .then((err) => {
                console.log(err)
            })
    },

    computed: {

    }
}
</script>